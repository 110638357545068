<template>
  <div
    class="cursor-pointer border rounded-md p-1 text-center"
    :class="{
      'bg-blueGray-50': !selected,
      'border-blueGray-300': !selected,
      'hover:bg-blueGray-200': !selected,
      'bg-sky-200': selected,
      'border-sky-600': selected,
      'hover:bg-sky-300': selected,
    }"
    @click="onClick"
  >
    <div v-if="loading" class="text-center flex justify-center items-center h-full">
      <MnrSpinner class="h-10 w-10" />
    </div>
    <template v-else>
      <div>{{ time.fa }}</div>
      <div>{{ time.time }}</div>
    </template>
  </div>
</template>

<script>
import MnrSpinner from "./mnr/MnrSpinner.vue";
export default {
  components: { MnrSpinner },
  props: {
    time: {
      default: function () {
        return {};
      },
      type: Object,
    },
    send: {
      default: function () {
        return {};
      },
      type: Object,
    },
    groupId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    selected() {
      return this.send?.send_at?.def == this.time.en;
    },
  },
  methods: {
    onClick() {
      this.loading = true;
      this.add();
    },
    add() {
      let $this = this;
      $this.$axios
        .post("api/ad-targets-select/" + $this.$route.params.adId, {
          id: $this.$store.state.user.id,
          time: $this.time.en,
          groupId: $this.groupId,
        })
        .then(function (response) {
          // console.log(response);
          $this.$emit("changeTime", response.data.ad);
          // $this.$router.push({ name: "Ad", params: { id: $this.$store.state.user.id } });
        })
        .catch()
        .finally(function () {
          $this.loading = false;
        });
    },
  },
};
</script>
