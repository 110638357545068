<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        بررسی نهایی تبلیغ هدفمند
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
        <li>
          در زیر می‌توانید لیست گروه ها و ساعت هایی که انتخاب کرده اید را مشاهده کنید و
          مواردی که نمی‌خواهید رو حذف کنید
        </li>
        <li>حداقل سفارش بدون در نظر گرفتن کلید ها 10 هزار تومان است</li>
        <li>دقت کنید در صورت حذف تبلیغ از گروه ها هزینه کلید ها برگردانده نمی‌شود</li>
      </ul>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <MnrSelect
        v-model="subKey.value"
        :options="subKey.options"
        title="کلید زیر بنر"
        placeholder="یک مورد را انتخاب کنید"
        hint="این کلید ها زیر بنر قرار می‌گیرد و با کلید شیشه‌ای متفاوت است"
      ></MnrSelect>
      <div>
        <div
          v-for="group in groups"
          :key="group.id"
          class="
            bg-blueGray-100
            border border-blueGray-200
            rounded-md
            my-5
            flex flex-wrap
            overflow-hidden
          "
        >
          <div
            class="
              w-full
              sm:w-5/12
              md:w-4/12
              lg:w-3/12
              border-b
              sm:border-l sm:border-b-0
              border-blueGray-200
              p-5
            "
          >
            <h2 class="text-md font-bold text-center">{{ group.name }}</h2>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group.members) }}</div>
              <div class="text-left">ممبر</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group?.info?.ad_price) }}</div>
              <div class="text-left">تومان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ list_gender[group.info?.gender] ?? "هردو" }}</div>
              <div class="text-left">جنسیت</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.country?.fa_name || "-" }}</div>
              <div class="text-left">کشور</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.province?.name || "-" }}</div>
              <div class="text-left">استان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.city?.name || "-" }}</div>
              <div class="text-left">شهر</div>
            </div>
            <!-- <Button class="mt-3 text-sm h-8 lg:h-10" color="red">
              حذف کامل این گروه
            </Button> -->
          </div>
          <div
            class="
              w-full
              sm:w-7/12
              md:w-8/12
              lg:w-9/12
              grid grid-cols-2
              sm:grid-cols-3
              lg:grid-cols-4
              2xl:grid-cols-6
              auto-rows-max
              gap-2
              p-3
            "
          >
            <TargetTime
              v-for="time in group.times"
              :key="group.id + '_' + time.en"
              :time="time"
              :send="L_.find(this.sends?.[group.id], ['send_at.def', time.en]) ?? {}"
              :groupId="group.id"
              @changeTime="setSends($event)"
            />
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="grid grid-cols-3 sm:grid-cols-5 gap-1 md:gap-4 w-full">
        <div class="text-center flex justify-center items-center">
          {{ Object.values(this.sends).length }} گروه
        </div>
        <div class="text-center flex justify-center items-center">
          {{ L_.flatten(Object.values(this.sends)).length }} ارسال
        </div>
        <div class="text-center flex justify-center items-center">
          {{ subKey.value }} کلید زیر بنر
        </div>
        <div class="text-center flex justify-center items-center">
          {{ $number.format(price + subKey.value * 10000) }}
          تومان
        </div>
        <div class="text-center flex justify-center items-center col-span-2 sm:col-auto">
          <Button
            @click="submit"
            class="text-sm h-10 lg:h-12"
            color="green"
            :disabled="price < 10000"
          >
            بررسی و پرداخت
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import TargetTime from "@/components/TargetTime.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    Icon,
    Button,
    BodySimple,
    TargetTime,
    MnrSelect,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      subKey: {
        value: 0,
        options: [
          {
            value: 0,
            text: "بدون کلید",
          },
          {
            value: 1,
            text: "1 کلید",
            hint: "+10000",
          },
          {
            value: 2,
            text: "2 کلید",
            hint: "+20000",
          },
          {
            value: 3,
            text: "3 کلید",
            hint: "+30000",
          },
        ],
      },
      list_gender: {
        male: "آقا",
        female: "خانم",
      },
      errors: [],
      groups: [],
      sends: [],
      ad: {},
      L_: _,
    };
  },
  computed: {
    price() {
      return _.sumBy(_.flatten(Object.values(this.sends)), "price");
    },
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.getAd();
      })
      .catch();
  },
  methods: {
    getAd() {
      let $this = this;
      $this.$axios
        .get("api/ad-targets-select/" + $this.$route.params.adId + "/selected", {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          console.log(response);
          // console.log(response.data.groups);
          $this.groups = response.data.groups;
          $this.ad = _.omit(response.data.ad, "sends");
          $this.setSends(response.data.ad);
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    setSends(ad) {
      this.sends = _.groupBy(ad.sends, "group_id");
    },
    submit() {
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;

      $this.$axios
        .put("/api/ad-targets/" + $this.ad.id, {
          id: $this.$store.state.user.id,
          subKeys: $this.subKey.value,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "TargetOrderCheck",
            params: { id: $this.$store.state.user.id, payId: response.data.pay.id },
          });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
